
import { computed, defineComponent, reactive } from 'vue'
import { IdentityService, SendPasswordResetEmailCommand, UserSecurityChallengeDto } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { EmailRegex, SSNRegex } from '@/common/globals'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  setup() {
    const recaptcha = useReCaptcha()!
    const service = new IdentityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

    const model = reactive({
      challengeType: undefined as undefined | number,
      emailAddress: '',
      userFound: false,
      submitted: false,
      challenge: undefined as undefined | UserSecurityChallengeDto,
      challengeResponse: undefined as undefined | string,
      challengeAttemptResult: null as any,
      ssn: undefined as string | undefined,
      dateOfBirth: undefined as string | undefined
    })

    const search = async () => {
      return getChallenge()
    }

    const getChallenge = async () => {
      await recaptcha.recaptchaLoaded()

      service.getNextSecurityChallenge(model.emailAddress, await recaptcha.executeRecaptcha('NextSecurityChallenge')).then((challenge) => {
        model.challengeType = challenge.challengeType
        model.userFound = true
        model.challengeAttemptResult = null
        model.challenge = challenge
      })
        .catch((err) => {
          model.challengeAttemptResult = err.detail
        })
    }

    const validEmail = computed((): boolean => {
      return model.emailAddress.length > 0 && EmailRegex.test(model.emailAddress) && model.challengeAttemptResult !== 'Invalid email address'
    })

    const canSubmit = computed((): boolean => {
      let valid = false
      if (model.emailAddress.length > 0 && EmailRegex.test(model.emailAddress) && model.challengeAttemptResult !== 'Invalid email address') {
        if (model.challenge?.challengeType === 2 && model.ssn && model.dateOfBirth && SSNRegex.test(model.ssn) && model.dateOfBirth !== undefined) {
          valid = true
        }
        else if (model.challenge?.challengeType === 1 && model.challengeResponse) {
          valid = true
        } 
        else if (model.challenge?.challengeType === 3) {
          valid = true
        }
      }
      return valid
    })

    const tooManyFailures = computed((): boolean => {
      return model.challengeAttemptResult === 'Too many failed security challenges'
    })

    const sendPasswordResetEmail = async () => {
      await recaptcha.recaptchaLoaded()
      return service.sendPasswordResetEmail(new SendPasswordResetEmailCommand({
        emailAddress: model.emailAddress,
        userSecurityQuestionId: model.challenge?.id!,
        response: model.challengeResponse,
        ssn: model.ssn,
        dateOfBirth: model.dateOfBirth ? new Date(model.dateOfBirth) : new Date(),
        recaptcha: await recaptcha.executeRecaptcha('SendPasswordResetEmail')
      })).then(result => {
        if (result) {
          model.submitted = true
        } else {
          getChallenge()
        }
      })
    }

    return {
      model,
      sendPasswordResetEmail,
      search,
      canSubmit,
      validEmail,
      tooManyFailures
    }
  }
})
