
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    className: {
      type: String,
      required: false
    }
  },
  setup(props) {
    return {}
  }
})
