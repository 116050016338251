import { CreateAnonymousTicketCommand, SecurityService } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { defineStore } from 'pinia'

const service = new SecurityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

export const useAnonymousSupportStore = defineStore('anonymous-support-store', {
  actions: { 
    async submitSupport(submitSupport: CreateAnonymousTicketCommand) {
      return await service.addAnonymousTicket(submitSupport)  
    }
  }
})
