import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelDynamic as _vModelDynamic } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["id", "value"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["value"]
const _hoisted_9 = { key: 3 }
const _hoisted_10 = {
  key: 4,
  class: "btn-group w-100",
  role: "group"
}
const _hoisted_11 = ["name", "id"]
const _hoisted_12 = ["for"]
const _hoisted_13 = ["name", "id"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { key: 5 }
const _hoisted_16 = ["type"]
const _hoisted_17 = { key: 6 }
const _hoisted_18 = { key: 7 }
const _hoisted_19 = { key: 8 }
const _hoisted_20 = { key: 9 }
const _hoisted_21 = { key: 10 }
const _hoisted_22 = ["value"]
const _hoisted_23 = { key: 11 }
const _hoisted_24 = ["value"]
const _hoisted_25 = { key: 12 }
const _hoisted_26 = ["value"]
const _hoisted_27 = { key: 13 }
const _hoisted_28 = ["value"]
const _hoisted_29 = { key: 14 }
const _hoisted_30 = ["value"]
const _hoisted_31 = { key: 15 }
const _hoisted_32 = ["value"]
const _hoisted_33 = { key: 16 }
const _hoisted_34 = ["value"]
const _hoisted_35 = { key: 17 }
const _hoisted_36 = {
  key: 18,
  class: "text-danger fw-bold validator-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_state_drop_down = _resolveComponent("state-drop-down")!
  const _component_month_year_selector = _resolveComponent("month-year-selector")!
  const _component_high_school_drop_down = _resolveComponent("high-school-drop-down")!

  return (_ctx.questionInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("fieldset", null, [
            (_ctx.questionInfo.inputType != 'extended-leadership')
              ? (_openBlock(), _createElementBlock("legend", {
                  key: 0,
                  class: "form-label fs-4",
                  innerHTML: _ctx.questionInfo.questionText
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.questionInfo.inputType == 'radio' && !_ctx.questionInfo.metadata.optionSource)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionInfo.metadata.answers, (o, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "mb-1",
                      key: o.value
                    }, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.modelValue = $event)),
                        type: "radio",
                        class: "btn-check",
                        name: "options",
                        id: `option${i}`,
                        autocomplete: "off",
                        value: o.value.toString()
                      }, null, 8, _hoisted_5), [
                        [_vModelRadio, _ctx.modelValue]
                      ]),
                      _createElementVNode("label", {
                        class: "btn btn-outline-primary w-100 text-start",
                        for: `option${i}`
                      }, _toDisplayString(o.text), 9, _hoisted_6)
                    ]))
                  }), 128))
                ]))
              : (_ctx.questionInfo.inputType == 'select' && _ctx.questionInfo.metadata && _ctx.questionInfo.metadata.answers)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-select",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionInfo.metadata.answers, (o) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: o.value,
                          value: o.value.toString()
                        }, _toDisplayString(o.text), 9, _hoisted_8))
                      }), 128))
                    ], 512), [
                      [_vModelSelect, _ctx.modelValue]
                    ])
                  ]))
                : (_ctx.questionInfo.inputType == 'select' && _ctx.questionInfo.metadata && _ctx.questionInfo.metadata.optionSource == 'States')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_state_drop_down, {
                        modelValue: _ctx.modelValue,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.modelValue = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : (_ctx.questionInfo.inputType == 'radio' && _ctx.questionInfo.metadata.optionSource == 'YesNo')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.modelValue = $event)),
                          name: `btnradio-yesno-${_ctx.questionInfo.id}`,
                          id: `btnradio-yesno-${_ctx.questionInfo.id}-1`,
                          value: "0",
                          autocomplete: "off"
                        }, null, 8, _hoisted_11), [
                          [_vModelRadio, _ctx.modelValue]
                        ]),
                        _createElementVNode("label", {
                          class: "btn btn-outline-primary",
                          for: `btnradio-yesno-${_ctx.questionInfo.id}-1`
                        }, "No", 8, _hoisted_12),
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          class: "btn-check",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.modelValue = $event)),
                          name: `btnradio-yesno-${_ctx.questionInfo.id}`,
                          id: `btnradio-yesno-${_ctx.questionInfo.id}-2`,
                          value: "1",
                          autocomplete: "off"
                        }, null, 8, _hoisted_13), [
                          [_vModelRadio, _ctx.modelValue]
                        ]),
                        _createElementVNode("label", {
                          class: "btn btn-outline-primary",
                          for: `btnradio-yesno-${_ctx.questionInfo.id}-2`
                        }, "Yes", 8, _hoisted_14)
                      ]))
                    : (_ctx.questionInfo.inputType == 'text')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.modelValue = $event)),
                            type: _ctx.questionStore.getInputTypeById(_ctx.questionInfo.id),
                            class: "form-control"
                          }, null, 8, _hoisted_16), [
                            [_vModelDynamic, _ctx.modelValue]
                          ])
                        ]))
                      : (_ctx.questionInfo.inputType === 'monthyeardate')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createVNode(_component_month_year_selector, {
                              modelValue: _ctx.modelValue,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.modelValue = $event)),
                              "start-year": new Date().getFullYear(),
                              "prior-years": 50
                            }, null, 8, ["modelValue", "start-year"])
                          ]))
                        : (_ctx.questionInfo.inputType === 'select' && _ctx.questionInfo.metadata.optionSource == 'ARHighSchools')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              _createVNode(_component_high_school_drop_down, {
                                modelValue: _ctx.modelValue,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.modelValue = $event)),
                                "school-type": _ctx.HighSchoolTypeEnum.AllHighSchools
                              }, null, 8, ["modelValue", "school-type"])
                            ]))
                          : (_ctx.questionInfo.inputType === 'select' && _ctx.questionInfo.metadata.optionSource == 'ARPublicHighSchools')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createVNode(_component_high_school_drop_down, {
                                  modelValue: _ctx.modelValue,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.modelValue = $event)),
                                  "school-type": _ctx.HighSchoolTypeEnum.PublicHighSchool
                                }, null, 8, ["modelValue", "school-type"])
                              ]))
                            : (_ctx.questionInfo.inputType === 'select' && _ctx.questionInfo.metadata.optionSource == 'ARPrivateHighSchools')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                  _createVNode(_component_high_school_drop_down, {
                                    modelValue: _ctx.modelValue,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.modelValue = $event)),
                                    "school-type": _ctx.HighSchoolTypeEnum.PrivateHighSchool
                                  }, null, 8, ["modelValue", "school-type"])
                                ]))
                              : (_ctx.questionInfo.inputType == 'select' && (_ctx.questionInfo.metadata.optionSource == 'ARHEGPrograms' || _ctx.questionInfo.metadata.optionSource == 'ARHEGFields'))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                    _withDirectives(_createElementVNode("select", {
                                      class: "form-select",
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.modelValue = $event))
                                    }, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadataStore.metadata?.arhegFields, (o) => {
                                        return (_openBlock(), _createElementBlock("option", {
                                          key: o.id,
                                          value: o.id
                                        }, _toDisplayString(o.name), 9, _hoisted_22))
                                      }), 128))
                                    ], 512), [
                                      [_vModelSelect, _ctx.modelValue]
                                    ])
                                  ]))
                                : (_ctx.questionInfo.inputType == 'select' && _ctx.questionInfo.metadata.optionSource == 'ARHEGColleges')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                      _withDirectives(_createElementVNode("select", {
                                        class: "form-select",
                                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.modelValue = $event))
                                      }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadataStore.arhegColleges, (o) => {
                                          return (_openBlock(), _createElementBlock("option", {
                                            key: o.id,
                                            value: o.id
                                          }, _toDisplayString(o.name), 9, _hoisted_24))
                                        }), 128))
                                      ], 512), [
                                        [_vModelSelect, _ctx.modelValue]
                                      ])
                                    ]))
                                  : (_ctx.questionInfo.inputType == 'select' && _ctx.questionInfo.metadata.optionSource == 'ShortageSubjectAreas')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                        _withDirectives(_createElementVNode("select", {
                                          class: "form-select",
                                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.modelValue = $event))
                                        }, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadataStore.shortageMetadata?.shortageSubjectAreas, (o) => {
                                            return (_openBlock(), _createElementBlock("option", {
                                              key: o.id,
                                              value: o.id
                                            }, _toDisplayString(o.name), 9, _hoisted_26))
                                          }), 128))
                                        ], 512), [
                                          [_vModelSelect, _ctx.modelValue]
                                        ])
                                      ]))
                                    : (_ctx.questionInfo.inputType == 'select' && _ctx.questionInfo.metadata.optionSource == 'ActiveColleges')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                          _withDirectives(_createElementVNode("select", {
                                            class: "form-select",
                                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.modelValue = $event))
                                          }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadataStore.activeColleges, (o) => {
                                              return (_openBlock(), _createElementBlock("option", {
                                                key: o.id,
                                                value: o.id
                                              }, _toDisplayString(o.name), 9, _hoisted_28))
                                            }), 128))
                                          ], 512), [
                                            [_vModelSelect, _ctx.modelValue]
                                          ])
                                        ]))
                                      : (_ctx.questionInfo.inputType == 'select' && _ctx.questionInfo.metadata.optionSource == 'AdditonalEducationCategory')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                            _withDirectives(_createElementVNode("select", {
                                              class: "form-select",
                                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.modelValue = $event))
                                            }, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadataStore.additionalEducationCategories, (o) => {
                                                return (_openBlock(), _createElementBlock("option", {
                                                  key: o.id,
                                                  value: o.id
                                                }, _toDisplayString(o.name), 9, _hoisted_30))
                                              }), 128))
                                            ], 512), [
                                              [_vModelSelect, _ctx.modelValue]
                                            ])
                                          ]))
                                        : (_ctx.questionInfo.inputType == 'select' && _ctx.questionInfo.metadata.optionSource == 'ShortageGeographicDistricts')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                              _withDirectives(_createElementVNode("select", {
                                                class: "form-select",
                                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.modelValue = $event))
                                              }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadataStore.shortageMetadata?.shortageDistricts, (o) => {
                                                  return (_openBlock(), _createElementBlock("option", {
                                                    key: o.id,
                                                    value: o.id
                                                  }, _toDisplayString(o.name), 9, _hoisted_32))
                                                }), 128))
                                              ], 512), [
                                                [_vModelSelect, _ctx.modelValue]
                                              ])
                                            ]))
                                          : (_ctx.questionInfo.inputType == 'date')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                                _withDirectives(_createElementVNode("select", {
                                                  class: "form-select",
                                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.modelValue = $event))
                                                }, [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionStore.yearOptions, (o) => {
                                                    return (_openBlock(), _createElementBlock("option", {
                                                      key: o,
                                                      value: o
                                                    }, _toDisplayString(o), 9, _hoisted_34))
                                                  }), 128))
                                                ], 512), [
                                                  [_vModelSelect, _ctx.modelValue]
                                                ])
                                              ]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_35, "NEED TO IMPLEMENT FOR " + _toDisplayString(_ctx.questionInfo.inputType) + " - " + _toDisplayString(_ctx.questionInfo.metadata), 1)),
            (_ctx.validator && _ctx.validator.$error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validator.$errors, (e) => {
                      return (_openBlock(), _createElementBlock("li", { key: e }, _toDisplayString(e.$message), 1))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}