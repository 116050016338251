import { CreateTicketIHEHSLoginRequestCommand, SecurityService } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { defineStore } from 'pinia'

const service = new SecurityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

export const useLoginRequestSupportStore = defineStore('login-request-support-store', {
  actions: { 
    async submitRequest(submitRequest: CreateTicketIHEHSLoginRequestCommand) {
      service.iHEHSLoginRequestTicket(submitRequest)
    }
  }
})
