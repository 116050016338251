import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: require('@/assets/img-register-scholarship.jpg'),
    class: _normalizeClass(_ctx.className),
    alt: ""
  }, null, 10, _hoisted_1))
}