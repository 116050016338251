import { createApi } from '@/plugin/Api'
import { ApplicationQualifyingProgramInfoDto, ApplicationResponse, ApplicationService, CreatedApplicationQualifyingPrograms } from '@/generated/public.api.clients'
import { defineStore } from 'pinia'
import { APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY } from '@/common/globals'

const service = new ApplicationService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

export const useApplicationStore = defineStore('application-store', {
  state: () => {
    return {
      id: null as number | null,
      userId: undefined as string | undefined,
      response: {} as Record<string, ApplicationResponse>,
      newApplication: undefined as number | undefined,
      accessToken: undefined as string | undefined,
      createdApplicationInformation: undefined as CreatedApplicationQualifyingPrograms | undefined,
      qualificationResults: [] as ApplicationQualifyingProgramInfoDto[]
    }
  },
  actions: {
    async intializeApplication (): Promise<Number> {
      this.id = Math.random() * 100
      return Promise.resolve(this.id)
    },
    setApplicantResponse (type: string, questionId: number, response: ApplicationResponse): void {
      this.response[questionId] = response
    },
    async getPreviousApplicationResponses (responseType: string[]): Promise<void> {
      const previousResponses = await service.getPreviousApplicationResponses(responseType)
      for (const r in previousResponses) {
        this.response[r] = new ApplicationResponse({ answerValue: previousResponses[r].answerValue, answerText: previousResponses[r].answerText, id: 0 })
      }
    },
    // ensureSearchResults() {
    //   if (!this.qualificationResults) {
    //     const localCache = localStorage.getItem(APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY) || '{}'
    //     this.qualificationResults = JSON.parse(localCache).qualificationResults
    //   }
    // },
    async createApplication(recaptcha: string): Promise<void> {
      const result = await service.createApplication(this.response, recaptcha)
      this.newApplication = result.applicationId
      this.accessToken = result.accessToken
      this.qualificationResults = result.qualificationResults!
      localStorage.setItem(APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY, JSON.stringify(result))
    },
    async loadPotentialAwardInformation (id: number, recaptcha: string, accessToken: string | undefined): Promise<void> {
      this.createdApplicationInformation = await service.getApplicationPotentialAward(id, (this.accessToken || accessToken)!, recaptcha)
    }
  }
})

export type ApplicationStore = Omit<
    ReturnType<typeof useApplicationStore>,
    keyof ReturnType<typeof defineStore>
>
