
import { defineComponent, inject } from 'vue'
import QuestionBlock from './ApplicationQuestion.vue'
import { SearchPageKey } from '@/components/searchAndApply/SearchAndApplyPageLogic'

export default defineComponent({
  components: {
    QuestionBlock
  },
  setup () {
    const searchPage = inject(SearchPageKey)!
    console.log('response page: setup')

    return {
      searchPage
    }
  }
})
