import Login from '../../views/login/Login.vue'
import ForgotEmail from '../../views/login/ForgotEmail.vue'
import ForgotPassword from '../../views/login/ForgotPassword.vue'
import Help from '../../views/login/Help.vue'
import ResetPassword from '../../views/login/ResetPassword.vue'
import Register from '../../Register.vue'
import SecurityUpdate from '../../views/login/SecurityUpdate.vue'

export const routes = [
  {
    name: 'Public/Login',
    path: 'login',
    component: Login
  },
  {
    name: 'Public/SecurityUpdate',
    path: 'security-update',
    component: SecurityUpdate
  },
  {
    name: 'Public/Register',
    path: 'register',
    component: Register
  },
  {
    name: 'Public/Login/Help',
    path: 'login/help',
    component: Help
  },
  {
    name: 'Public/Login/ForgotEmail',
    path: 'login/help/forgot-email',
    component: ForgotEmail
  },
  {
    name: 'Public/Login/ResetPassword',
    path: 'login/help/reset-password',
    component: ResetPassword
  },
  {
    name: 'Public/Login/ForgotPassword',
    path: 'login/help/forgot-password',
    component: ForgotPassword
  }
]
