
import { defineComponent, Ref, ref, watch, inject, computed, onBeforeMount } from 'vue'
import { StoreKeys } from '@/store/StoreKeys'
import { HighSchoolTypeEnum, useMetadataStore } from '@/store/MetadataStore'
import StateDropDown from '@/components/common/StateDropDown.vue'
import HighSchoolDropDown from '@/components/common/HighSchoolDropDown.vue'
import { useApplicationStore } from '@/store/ApplicationStore'
import MonthYearSelector from '@/components/searchAndApply/MonthYearSelector.vue'
import { ApplicationResponse, IApplicationQuestionDto } from '@/generated/public.api.clients'
import { useApplicationQuestionStore } from '@/store/ApplicationQuestionStore'

export default defineComponent({
  components: {
    StateDropDown,
    HighSchoolDropDown,
    MonthYearSelector
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    validator: Object
  },
  setup (props) {
    const questionStore = useApplicationQuestionStore()
    const applicationStore = useApplicationStore()

    const modelValue: Ref<string | number | undefined> = ref(applicationStore.response[props.question.id]?.answerValue)

    const metadataStore = useMetadataStore()
    const utility = inject(StoreKeys.SearchAndApplyUtilityKey)!
    const answer = computed(() => {
      return applicationStore.response[questionInfo.value.id]
    })

    onBeforeMount(() => {
      if (questionInfo.value.inputType === 'select' && answer.value) {
        modelValue.value = answer.value.answerValue
      }
    })

    const questionInfo = computed((): IApplicationQuestionDto => {
      return questionStore?.getQuestionById(Number(props.question.id))!
    })

    watch(modelValue, async (newValue) => {
      // console.log(`Model value changed to ${modelValue.value} on questionInfo ${questionInfo.value.id}`)
      if (typeof (newValue) !== 'undefined') {
        await applicationStore.setApplicantResponse('Search', questionInfo.value.id, new ApplicationResponse({ id: undefined, answerValue: newValue.toString(), answerText: utility.getAnswerText(newValue, questionInfo.value) }))
      }
    })

    return {
      questionInfo,
      questionStore,
      HighSchoolTypeEnum,
      modelValue,
      answer,
      metadataStore: metadataStore!
    }
  }
})
