
import { defineComponent, reactive } from 'vue'
import { ApplicantService, ForgotEmailCommand } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { useReCaptcha } from 'vue-recaptcha-v3'
const SSN_REGEX = /(^\d{3}-\d{2}-\d{4}|\d{9})/

export default defineComponent({
  setup () {
    const recaptcha = useReCaptcha()!

    const model = reactive({
      ssn: '',
      dateOfBirth: '',
      emails: [] as string[] | undefined,
      submitted: false,
      success: false,
    })

    const canSubmit = () => {
      return SSN_REGEX.test(model.ssn) && model.dateOfBirth.length > 0
    }

    const applicantService = new ApplicantService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

    const getEmails = async () => {
      await recaptcha.recaptchaLoaded()

      return applicantService.forgotEmailAddress(new ForgotEmailCommand({
        ssn: model.ssn.replace(/-/g, ''),
        dob: new Date(model.dateOfBirth.toString()),
        recaptcha: await recaptcha.executeRecaptcha('ForgotEmail'),
      })).then((results) => {
        console.log('parent then')
        model.emails = results
        model.success = true
        model.submitted = true
      }).catch(_err => {
        console.log('parent catch')
        model.success = false
        model.submitted = true
        throw _err
      })
    }

    return {
      model,
      getEmails,
      canSubmit,
      SSN_REGEX
    }
  }
})
