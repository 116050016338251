import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"color":"white"} }
const _hoisted_3 = { style: {"color":"white"} }
const _hoisted_4 = { style: {"color":"white"} }
const _hoisted_5 = { style: {"color":"white"} }
const _hoisted_6 = { class: "text-white" }
const _hoisted_7 = { class: "text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_block = _resolveComponent("question-block")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.searchPage.getters.currentPage.value.id), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.searchPage.getters.currentPage.value.requirement), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.searchPage.getters.currentPage.value.routing), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.searchPage.getters.currentPage.value.section), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.searchPage.getters.currentPage.value.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchPage.getters.sectionedPageQuestions.value, (section) => {
      return (_openBlock(), _createElementBlock("div", {
        key: section.sectionName
      }, [
        _createElementVNode("h2", _hoisted_7, _toDisplayString(section.sectionName), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.questions, (q) => {
          return (_openBlock(), _createElementBlock("div", {
            key: q.id
          }, [
            _createVNode(_component_question_block, {
              class: "mb-3",
              question: q,
              validator: _ctx.searchPage.v$.value[q.id]
            }, null, 8, ["question", "validator"])
          ]))
        }), 128))
      ]))
    }), 128))
  ], 64))
}