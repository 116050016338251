
/* eslint-disable */
/* vue/no-unused-components */
import { computed, defineComponent, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createApi } from '@/plugin/Api'
import { IdentityService, LoginAttemptCommand } from '@/generated/public.api.clients'
import { useCachedInProgressApplication } from '@/common/inProgressApplication'
import { useReCaptcha } from 'vue-recaptcha-v3'
import ImgLogin from '@/components/common/layout/ImgLogin.vue'
import ImgRegisterScholarship from '@/components/common/layout/ImgRegisterScholarship.vue'

export default defineComponent({
  components: {
    ImgLogin,
    ImgRegisterScholarship
  },
  setup() {
    const recaptcha = useReCaptcha()!

    const model = reactive({
      username: '',
      password: '',
      rememberMe: false,
      returnUrl: undefined,
      securityWorkflow: null as string | null,
      recaptcha: '',
      continueLoadingOnSuccess: true
    })

    const errorMessage = ref('')
    const service = new IdentityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
    const route = useRoute()
    const router = useRouter()

    const { applicationInProgress } = useCachedInProgressApplication()

    const canLogin = computed(() => {
      return model.username.length > 0 && model.password.length > 0
    })

    const onLogin = async () => {
      await recaptcha.recaptchaLoaded()
      model.recaptcha = await recaptcha.executeRecaptcha('Login')

      errorMessage.value = ''
      model.continueLoadingOnSuccess = true

      return await service.login(new LoginAttemptCommand(model))
        .then((loginAttemptResponse) => {
          if (loginAttemptResponse.result?.succeeded) {
            if (!loginAttemptResponse.securityWorkflowCompleted) {
              router.push({ name: 'Public/SecurityUpdate' })
            } else {
              console.log(process.env.VUE_APP_PORTAL_REDIRECT_URL)
              // redirect to the portal redirect url after login; it will redirect to the appropriate portal
              window.location.assign(process.env.VUE_APP_PORTAL_REDIRECT_URL + (route.query.ReturnUrl ? '?ReturnUrl=' + route.query.ReturnUrl : ''))
            }
          } else {
            // handle login failure
            model.continueLoadingOnSuccess = false
            if (loginAttemptResponse.result?.isLockedOut) errorMessage.value = 'Your account has been locked out.'
            else if (loginAttemptResponse.result?.isNotAllowed && loginAttemptResponse.emailNotConfirmed) errorMessage.value = 'Your account is not allowed to login because your email address has not been confirmed.  Please check your email for a confirmation link.'
            else if (loginAttemptResponse.result?.isNotAllowed) errorMessage.value = 'Your account is not allowed to login.'
            else if (loginAttemptResponse.result?.requiresTwoFactor) errorMessage.value = 'Your account requires two factor authentication.'
            else errorMessage.value = 'Please check your email address and password and try again.'
          }
        })     
    }

    return {
      applicationInProgress,
      model,
      onLogin,
      errorMessage,
      canLogin
    }
  }
})
