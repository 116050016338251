
import { useMetadataStore } from '@/store/MetadataStore'
import { defineComponent, onBeforeMount } from 'vue'

export default defineComponent({
  setup () {
    const metadataStore = useMetadataStore()

    onBeforeMount(async () => {
      await metadataStore.initialize()
    })

    return {
    }
  }
})
