
import { defineComponent, inject } from 'vue'
import { SearchPageKey } from '@/components/searchAndApply/SearchAndApplyPageLogic'

export default defineComponent({
  components: {
  },
  setup () {
    const searchPage = inject(SearchPageKey)!

    return {
      searchPage
    }
  }
})
