
import { useRouter } from 'vue-router'
import { defineComponent, onBeforeMount, ref, Ref, provide } from 'vue'
import { StoreKeys } from '@/store/StoreKeys'
import LoadingPanel from '@/components/common/LoadingPanel.vue'
import { SearchPage, SearchPageKey } from '@/components/searchAndApply/SearchAndApplyPageLogic'
import { SearchAndApplyUtility } from '@/components/searchAndApply/SearchAndApply'
import { useApplicationQuestionStore } from '@/store/ApplicationQuestionStore'
import { useApplicationStore } from '@/store/ApplicationStore'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { appCookieExists } from '@/common/helpers'

export default defineComponent({
  components: {
    LoadingPanel
  },
  setup() {
    const recaptcha = useReCaptcha()!
    const router = useRouter()

    const searchAndApplyUtility = new SearchAndApplyUtility()
    provide(StoreKeys.SearchAndApplyUtilityKey, searchAndApplyUtility)

    const applicationStore = useApplicationStore()
    const store = useApplicationQuestionStore()

    const submitSearch = async () => {
      await recaptcha.recaptchaLoaded()
      await applicationStore.createApplication(await recaptcha.executeRecaptcha('CreateApplication'))
      router.push({ name: 'Public/Search/Results', params: { aid: applicationStore.newApplication } })
    }

    const searchPage = new SearchPage(applicationStore.response, submitSearch)
    provide(SearchPageKey, searchPage)

    const loading: Ref<Promise<void[]>> = ref(Promise.all([Promise.resolve()]))

    onBeforeMount(async () => {
      loading.value = Promise.all<void>([
        store?.getQuestions()!,
        appCookieExists() ? applicationStore.getPreviousApplicationResponses(['Search']) : Promise.resolve()
      ])
      searchPage.actions.setPage(0)
    })

    return {
      loading
    }
  }
})
