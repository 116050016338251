
import { IdentityService, ChangeEmailAddressCommand } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { defineComponent, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  setup () {
    const recaptcha = useReCaptcha()!
    const route = useRoute()
    const router = useRouter()
    const service = new IdentityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

    const emails = computed((): {current:string, updated: string} => {
      const decoded = atob(route.query.uvt as string)
      const split = decoded.split('|')
      return { current: split[0], updated: split[1] }
    })

    const confirm = async () => {
      await recaptcha.recaptchaLoaded()

      const result = await service.changeEmailAddress(new ChangeEmailAddressCommand({ payload: route.query.payload as string, newEmail: emails.value.updated, recaptcha: await recaptcha.executeRecaptcha('ChangeEmail') }))
      console.log(`Email Changed ${result}`)

      if (result) {
        router.replace({ name: 'Public/Login' })
      }
    }

    const cancel = () => {
      router.replace({ name: 'Public/Login' })
    }

    return {
      emails,
      confirm,
      cancel
    }
  }
})
