import { createRouter, createWebHistory } from 'vue-router'
import Home from '../Home.vue'
import Search from '../views/search/Search.vue'
import SearchHome from '../views/search/Home.vue'
import Ineligible from '../views/search/Ineligible.vue'
import Results from '../views/search/Results.vue'
import UpdateEmailConfirm from '../views/utility/UpdateEmail.vue'
import Support from '../views/support/Home.vue'
import LoginRequest from '../views/support/LoginRequest.vue'
import InformationSheet from '../views/InformationSheet.vue'
import { routes as LoginRoutes } from '../views/login/routes'
const routes = [
  {
    name: 'Public/Home',
    path: '/',
    component: Home,
    children: [
      ...LoginRoutes,
      {
        name: 'Public/UpdateEmailConfirm',
        path: 'update-email',
        component: UpdateEmailConfirm
      },
      {
        name: 'Public/Search/Home',
        path: 'search',
        redirect: { name: 'Public/Search/Search' },
        component: SearchHome,
        children: [
          {
            name: 'Public/Search/Search',
            path: '',
            component: Search
          },
          {
            name: 'Public/Search/Ineligible',
            path: 'ineligible',
            component: Ineligible
          },
          {
            name: 'Public/Search/Results',
            path: 'results/:aid',
            component: Results,
            props: true
          }
        ]
      },
      {
        name: 'Public/Support',
        path: 'support',
        component: Support
      },
      {
        name: 'Public/Support/LoginRequest',
        path: 'support/login-request',
        component: LoginRequest
      },
      {
        name: 'Public/InformationSheet',
        path: 'information-sheet/:id',
        component: InformationSheet
      }
    ]
  }
]

const MODULE_ROOT = 'public'
const router = createRouter({
  history: createWebHistory(MODULE_ROOT),
  routes
})

export default router
