
import { defineComponent, inject, onBeforeMount } from 'vue'
import ResponsePage from './components/ApplicationResponsePage.vue'
import { SearchPageKey } from '@/components/searchAndApply/SearchAndApplyPageLogic'
import { useMetadataStore } from '@/store/MetadataStore'
export default defineComponent({
  components: {
    ResponsePage
  },
  setup () {
    const searchPage = inject(SearchPageKey)!
    const metadataStore = useMetadataStore()

    onBeforeMount(() => {
      metadataStore.getShortageMetadata()
    })

    return {
      searchPage
    }
  }
})
