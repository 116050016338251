
import { defineComponent } from 'vue'
import HeaderLogos from '@/components/common/layout/HeaderLogos.vue'
import FooterContent from '@/components/common/layout/FooterContent.vue'

export default defineComponent({
  components: {
    HeaderLogos,
    FooterContent
  }
})
