
/* eslint-disable */
/* vue/no-unused-components */
import { defineComponent, ref } from 'vue'
import { useToast } from 'vue-toast-notification'
import { EmailRegex, SSNRegex } from '@/common/globals'
import { CreateAnonymousTicketCommand } from '@/generated/public.api.clients'
import { useAnonymousSupportStore } from '../../../../store/AnonymousSupportStore'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  setup() {
    const recaptcha = useReCaptcha()!
    const service = useAnonymousSupportStore()
    const toast = useToast()
    const ssn = ref('')
    const email = ref('')
    const dob = ref('')
    const description = ref('')
    const isSubmitted = ref(false)

    const actions = {
      submitSupport: async () => {
        await recaptcha.recaptchaLoaded()
        const data = new CreateAnonymousTicketCommand()
        data.anonymousSSN = ssn.value
        data.anonymousEmail = email.value
        data.anonymousDoB = new Date(dob.value)
        data.description = description.value.trim()
        data.recaptcha = await recaptcha.executeRecaptcha('SubmitPublicTicketSupport')
        
        const isValidFields = data.anonymousSSN.length > 0 && data.anonymousEmail.length > 0 && data.anonymousDoB != null && data.description.trim().length > 0 && recaptcha !== undefined
        const isValidSSN = SSNRegex.test(ssn.value)
        const isValidEmail = EmailRegex.test(email.value)

        if (isValidFields) {
          if (!isValidSSN || !isValidEmail) {
            if (!isValidSSN) {
              toast.error('Failed: SSN format is invalid.', { position: 'bottom' })
            }

            if (!isValidEmail) {
              toast.error('Failed: Email format is invalid.', { position: 'bottom' })
            }
          } else {
            await service.submitSupport(data)
            ssn.value = ''
            email.value = ''
            dob.value = ''
            description.value = ''
            isSubmitted.value = true
          }
        } else {
          toast.error('Failed: Please fill in the fields.', { position: 'bottom' })
        }
      }
    }

    return {
      ssn,
      email,
      dob,
      description,
      isSubmitted,
      actions
    }
  }
})
