import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-nav" }
const _hoisted_2 = { class: "splash" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_logos = _resolveComponent("header-logos")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_content = _resolveComponent("footer-content")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_header_logos, { "top-route": { name: 'Public/Login' } }),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_footer_content)
    ])
  ]))
}