
import { computed, defineComponent, inject, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { SearchPageKey } from '@/components/searchAndApply/SearchAndApplyPageLogic'
import { sumBy, max } from 'lodash'
import { toCurrency } from '@/common/formatters/numeric'
import { dateTimeFormatters, jsonStringToDate } from '@/common/formatters/dateTime'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { QualificationResultEnum, ApplicationQualifyingProgramInfoDto } from '@/generated/public.api.clients'
import { useCachedInProgressApplication } from '@/common/inProgressApplication'
import { appCookieExists } from '@/common/helpers'
import { APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY } from '@/common/globals'
export default defineComponent({
  components: {
  },
  props: {
    aid: String
  },
  setup() {
    const recaptcha = useReCaptcha()!
    const route = useRoute()
    const { applicationInProgress } = useCachedInProgressApplication()
    const searchPage = inject(SearchPageKey)!

    const openQualifiedPrograms = computed(() => applicationInProgress.value?.qualificationResults!.filter(v => v.result === QualificationResultEnum.QualfiedToApply) || [])
    const qualfiedGSAwards = computed(() => openQualifiedPrograms.value.filter(v => /gs(.*)/i.test(v.abbreviation || '')))
    const qualfiedACSAwards = computed(() => openQualifiedPrograms.value.filter(v => /acs(.*)/i.test(v.abbreviation || '')))
    const isQualifiedForACSAndGS = computed(() => qualfiedACSAwards.value.length > 0 && qualfiedGSAwards.value.length > 0)

    const qualfiesForOpenPrograms = computed(() => openQualifiedPrograms.value.length > 0)
    const qualfiesForClosedPrograms = computed(() => (applicationInProgress.value?.qualificationResults!.filter(v => v.result === QualificationResultEnum.ApplicationWindowNotYetOpen) || []).length > 0)
    const otherDisqualifiedPrograms = computed(() => (applicationInProgress.value?.qualificationResults!.filter(v => v.result === QualificationResultEnum.OtherDisqualifiction || v.result === QualificationResultEnum.PastDeadline) || []))

    const totalPotentialAward = computed(() => {
      let totalDisplay = 'Varies'
      const amountFromAllOpenQualifiedPrograms = sumBy(openQualifiedPrograms.value, p => p.potentialAwardAmount || 0)
      const amountFromGS = sumBy(qualfiedGSAwards.value, p => p.potentialAwardAmount || 0)
      const amountFromACS = sumBy(qualfiedACSAwards.value, p => p.potentialAwardAmount || 0)
      const varyingAwardsQualifying = openQualifiedPrograms.value.filter(p => typeof (p.potentialAwardAmount) === 'undefined').length

      const tempAdjustedAmount = amountFromAllOpenQualifiedPrograms - (amountFromACS + amountFromGS)
      const acsOrGsContribution = max([amountFromACS, amountFromGS]) || 0

      if (amountFromAllOpenQualifiedPrograms > 0) {
        totalDisplay = toCurrency(tempAdjustedAmount + acsOrGsContribution) || 'Varies'

        const applyPlusSuffix = (amountFromACS > 0 && amountFromGS === 0) || (amountFromAllOpenQualifiedPrograms > 0 && varyingAwardsQualifying > 0)
        if (applyPlusSuffix) {
          totalDisplay += '+'
        }
      }
      return totalDisplay
    })

    const apply = () => {
      // const item = JSON.stringify(Object.assign({ applicationDate: new Date() }, applicationStore.createdApplicationInformation))
      // localStorage.setItem(APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY, item)
      const applyUrl = `${process.env.VUE_APP_APPLICANT_PORTAL_URL}/application?id=${applicationInProgress.value?.applicationId}&token=${applicationInProgress.value?.accessToken}`
      window.location.assign(applyUrl)
    }

    onBeforeMount(async () => {
      await recaptcha.recaptchaLoaded()
    })

    const awardAmountFormatted = (awardProgram: ApplicationQualifyingProgramInfoDto) => {
      let displayAmount = 'Varies'

      if (awardProgram.potentialAwardAmount) {
        displayAmount = `${toCurrency(awardProgram.potentialAwardAmount)}`

        if (/acs(.*)/i.test(awardProgram.abbreviation || '')) {
          displayAmount += '+'
        }
      }

      return displayAmount
    }

    const goHome = () => {
      let abandon = true
      if (qualfiesForOpenPrograms.value && !confirm('If you continue, your search results will be lost. You will have to start the process again at a later time. Are you sure you want to do this?')) 
      {
        abandon = false
      }

      if (abandon) {
        localStorage.removeItem(APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY)
        const path = appCookieExists() ? process.env.VUE_APP_PORTAL_REDIRECT_URL : '/public/login'
        window.location.assign(path)
      }
    }

    return {
      searchPage,
      applicationInProgress,
      apply,
      route,
      qualfiesForOpenPrograms,
      qualfiesForClosedPrograms,
      otherDisqualifiedPrograms,
      totalPotentialAward,
      awardAmountFormatted,
      dateTimeFormatters,
      QualificationResultEnum,
      jsonStringToDate,
      loggedIn: appCookieExists(),
      goHome,
      isQualifiedForACSAndGS
    }
  }
})
