

import { defineComponent, ref, onMounted, watchEffect } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore'
import { useLoginRequestSupportStore } from '../../../../store/LoginRequestSupportStore'
import { CreateTicketIHEHSLoginRequestCommand } from '@/generated/public.api.clients'
import { EmailRegex } from '@/common/globals'
import { useToast } from 'vue-toast-notification'
import { useReCaptcha } from 'vue-recaptcha-v3'
import Multiselect from 'vue-multiselect'

export default defineComponent({
  components: {
    Multiselect
  },
  setup() {
    const metadataStore = useMetadataStore()
    const store = useLoginRequestSupportStore()
    const recaptcha = useReCaptcha()!
    const toast = useToast()

    const contactFirstName = ref('')
    const contactLastName = ref('')
    const contactTitle = ref('')
    const accountIsCollegeFA = ref(false)
    const accountSelectedColleges = ref([] as any[])
    const accountIsHighSchool = ref(false)
    const accountSelectedHighSchools = ref([] as any[])
    const accountAddress = ref('')
    const accountCity = ref('')
    const accountState = ref('')
    const accountZip = ref('')
    const accountPhone = ref('')
    const loginEmail = ref('')
    const loginConfirmEmail = ref('')
    const notes = ref('')
    const isSubmitted = ref(false)

    const submitRequest = async () => {
      if (contactFirstName.value.trim().length > 0 && contactLastName.value.trim().length > 0) {
        if (loginEmail.value.trim().length > 0 && loginConfirmEmail.value.trim().length > 0) {
          if (EmailRegex.test(loginEmail.value) && EmailRegex.test(loginConfirmEmail.value)) {
            if (loginEmail.value === loginConfirmEmail.value) {
              if (contactTitle.value.trim().length > 0) {
                if (accountPhone.value.trim().length > 0) {
                  if (accountAddress.value.trim().length > 0 && accountCity.value.trim().length > 0 && accountState.value.trim().length > 0 && accountZip.value.trim().length > 0) {
                    if (accountIsCollegeFA.value || accountIsHighSchool.value) {
                      if (!accountIsCollegeFA.value || accountSelectedColleges.value.length > 0) {
                        if (!accountIsHighSchool.value || accountSelectedHighSchools.value.length > 0) {
                          const data = new CreateTicketIHEHSLoginRequestCommand()

                          // OBJECT FROM MULTISELECT MUST BE CONVERTED TO STRING ARRAY - COLLEGES
                          const colleges = accountSelectedColleges.value
                          const arrayCollege: string[] = []
                          if (colleges.length > 0) {
                            colleges.forEach(function (value, index) {
                              arrayCollege.push(colleges[index].name)
                            })
                          }

                          // OBJECT FROM MULTISELECT MUST BE CONVERTED TO STRING ARRAY - HIGH SCHOOL
                          const highSchools = accountSelectedHighSchools.value
                          const arrayHighSchool: string[] = []
                          if (highSchools.length > 0) {
                            highSchools.forEach(function (value, index) {
                              arrayHighSchool.push(highSchools[index].name)
                            })
                          }

                          data.firstName = contactFirstName.value
                          data.lastName = contactLastName.value
                          data.title = contactTitle.value
                          data.collegeFinancialAidOffice = accountIsCollegeFA.value
                          data.collegesSelected = arrayCollege
                          data.highSchoolCounselor = accountIsHighSchool.value
                          data.highSchoolsSelected = arrayHighSchool
                          data.address = accountAddress.value
                          data.city = accountCity.value
                          data.state = accountState.value
                          data.zip = accountZip.value
                          data.phone = accountPhone.value
                          data.email = loginEmail.value
                          data.confirmEmail = loginConfirmEmail.value
                          data.note = notes.value
                          data.recaptcha = await recaptcha.executeRecaptcha('SubmitLoginRequestSupport')

                          store.submitRequest(data)
                          isSubmitted.value = true

                          // RESET VALUES
                          data.firstName = ''
                          data.lastName = ''
                          data.title = ''
                          data.collegeFinancialAidOffice = false
                          data.collegesSelected = []
                          data.highSchoolCounselor = false
                          data.highSchoolsSelected = []
                          data.address = ''
                          data.city = ''
                          data.state = ''
                          data.zip = ''
                          data.phone = ''
                          data.email = ''
                          data.confirmEmail = ''
                          data.note = ''
                        } else {
                          toast.error('Please select your High School', { position: 'bottom' })
                        }
                      } else {
                        toast.error('Please select your College', { position: 'bottom' })
                      }
                    } else {
                      toast.error('At least one Account Type needed', { position: 'bottom' })
                    }
                  } else {
                    toast.error('Please enter your full address', { position: 'bottom' })
                  }   
                } else {
                  toast.error('Please enter your Phone', { position: 'bottom' })
                }
              } else {
                toast.error('Please enter your title', { position: 'bottom' })
              }
            } else {
              toast.error('Emails do not match.', { position: 'bottom' })
            }
          } else {
            toast.error('Email not in proper format.', { position: 'bottom' })
          }
        } else {
          toast.error('Please supply your email.', { position: 'bottom' })
        }
      } else {
        toast.error('Please enter your first and last name.', { position: 'bottom' })
      }
    }

    watchEffect(() => {
      accountIsCollegeFA
      accountIsHighSchool
    })

    onMounted(() => {
      metadataStore.getColleges()
      metadataStore.getHighSchools()
    })

    return {
      metadataStore,
      contactFirstName,
      contactLastName,
      contactTitle,
      accountIsCollegeFA,
      accountSelectedColleges,
      accountIsHighSchool,
      accountSelectedHighSchools,
      accountAddress,
      accountCity,
      accountState,
      accountZip,
      accountPhone,
      loginEmail,
      loginConfirmEmail,
      notes,
      isSubmitted,
      submitRequest
    }
  }
})
