
import { defineComponent, watch, reactive } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore'
import { useRoute } from 'vue-router'
export default defineComponent({
  setup() {
    const store = useMetadataStore()
    const route = useRoute()
    const state = reactive({
      text: undefined as string | undefined
    })

    watch(() => route.params.id, async () => {
      state.text = await store.getInformationSheetText(Number(route.params.id))
    }, { immediate: true })

    return {
      state
    }
  }
})
