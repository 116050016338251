import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import './styles/scss/public.scss'
import { createPinia } from 'pinia'
import Maska from 'maska'
import 'vue-toast-notification/dist/theme-sugar.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import ActivityButton from '@/components/common/ActivityButton.vue'
console.log('initializing public app')

const pinia = createPinia()

const app = createApp(App)
  .component('activity-button', ActivityButton)
  .use(router)
  .use(pinia)
  .use(Maska)
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY })
  .mount('#app')
