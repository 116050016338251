
import { defineComponent, onBeforeMount, reactive } from 'vue'
import { createApi } from '@/plugin/Api'
import { UserService, UpdateUserSecurityQuestionsCommand, ISecurityQuestionDto, MetadataService, UpdateSecurityQuestionsModel } from '@/generated/public.api.clients'
import { useReCaptcha } from 'vue-recaptcha-v3'

const service = new UserService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
const metadataService = new MetadataService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

export default defineComponent({
  setup() {
    const recaptcha = useReCaptcha()!

    const state = reactive({
      questionsUpdated: false,
      potentialSecurityQuestions: [] as ISecurityQuestionDto[] | null,
      selectedSecurityQuestions: [
        { securityQuestionId: undefined, order: 1, answer: undefined },
        { securityQuestionId: undefined, order: 2, answer: undefined },        
        { securityQuestionId: undefined, order: 3, answer: undefined } 
      ] as ({ securityQuestionId: number | undefined, order: number, answer: string | undefined})[],
    })

    onBeforeMount(async () => {
      state.potentialSecurityQuestions = await metadataService.getSecurityQuestions()
    })

    const updateSecurityQuestions = async () => {
      const payload = [] as UpdateSecurityQuestionsModel[]

      for (let i: number = 0; i < 3; i++) {
        if (state.selectedSecurityQuestions[i] && state.selectedSecurityQuestions[i]?.securityQuestionId && state.selectedSecurityQuestions[i]?.answer)
        {
          payload.push(new UpdateSecurityQuestionsModel({ securityQuestionId: state.selectedSecurityQuestions[i]!.securityQuestionId!, order: i + 1, answer: state.selectedSecurityQuestions[i]!.answer }))
        }
      }

      if (payload.length === 3) {
        await recaptcha.recaptchaLoaded()

        await service.updateUserSecurityQuestions(new UpdateUserSecurityQuestionsCommand({ securityAnswers: payload, recaptcha: await recaptcha.executeRecaptcha('UpdateUserSecurityQuestions') }))
        state.questionsUpdated = true
        localStorage.setItem('SAMSSecurityWorkflowCompleted', '1.0')
      }
    }

    const isValid = () => {
      return state.selectedSecurityQuestions.every(q => q.securityQuestionId && (q.answer || '').length > 2)
    }

    const questionSubset = (set:number): ISecurityQuestionDto[] | undefined => state.potentialSecurityQuestions?.filter(m => (m.id % 3) === set)

    return {
      state,
      updateSecurityQuestions,
      questionSubset,
      isValid,
      proceedToLogin: () => {
        window.location.assign(process.env.VUE_APP_PORTAL_REDIRECT_URL)
      }
    }
  }
})
