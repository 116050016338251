
import { computed, defineComponent, reactive } from 'vue'
import { ApplicantService, RegisterApplicantCommand, CreateUserCommand, AccountAssociationStatus, GetApplicantAccountAssociationOptionsQuery } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { email, helpers, maxLength, required, requiredIf } from '@vuelidate/validators'
import SchoolDistrictDropDown from '@/components/common/SchoolDistrictDropDown.vue'
import HighSchoolDropDown from '@/components/common/HighSchoolDropDown.vue'
import useVuelidate from '@vuelidate/core'
import PasswordFeedback from '@/components/common/PasswordFeedback.vue'
import { useReCaptcha } from 'vue-recaptcha-v3'

interface IState {
  step: number,
  publicSchoolStudent: number,
  applicant: {
    applicantId: number | undefined,
    username: string | null,
    password: string | null,
    confirmPassword: string | null,
    ssn: string | null,
    firstName: string | null,
    lastName: string | null,
    dob: Date | null,
    status: AccountAssociationStatus | null,
    name: string | undefined,
    district: string | undefined,
    districtId: number | undefined,
    school: string | undefined,
    highSchoolId: number | undefined
  },
  emailAvailable: boolean | undefined,
  accountCreated: boolean | undefined,
  passwordIsValid: false,
}

export default defineComponent({
  components: {
    SchoolDistrictDropDown,
    HighSchoolDropDown,
    PasswordFeedback,
  },
  setup() {
    const recaptcha = useReCaptcha()!
    const service = new ApplicantService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
    const steps: string[] = ['email-validation', 'applicant-school-status', 'applicant-link', 'applicant-link-results', 'finalize', 'finalize-results']

    const state: IState = reactive({
      step: 0,
      publicSchoolStudent: 1,
      applicant: {
        applicantId: undefined,
        username: '',
        password: '',
        confirmPassword: '',
        ssn: '###-##-####',
        firstName: '',
        lastName: '',
        dob: null,
        status: null,
        name: undefined,
        district: undefined,
        districtId: undefined,
        school: undefined,
        highSchoolId: undefined,
      },
      emailAvailable: undefined,
      accountCreated: undefined,
      passwordIsValid: false,
    })

    /* Computed */
    const cannotMatches = computed(() => [state.applicant.firstName, state.applicant.lastName, state.applicant.username])
    const currentStep = computed(() => steps[state.step])
    const minDOB = computed(() => {
      const date = new Date()
      date.setFullYear(date.getFullYear() - 95)
      return date.toISOString().split('T')[0]
    })
    const maxDOB = computed(() => {
      const date = new Date()
      date.setFullYear(new Date().getFullYear() - 10)
      return date.toISOString().split('T')[0]
    })

    const getApplicantByUserName = async () => {
      if (state.applicant.username) {
        await recaptcha.recaptchaLoaded()

        const applicant = await service.getApplicantByUserName(state.applicant.username, await recaptcha.executeRecaptcha('ApplicantUsername'))
        state.emailAvailable = !(applicant && applicant.userName)
      }
    }

    const getApplicantAccountAssociationOptions = async () => {
      if (state.applicant.dob && state.applicant.ssn) {
        await recaptcha.recaptchaLoaded()

        const dob = new Date(state.applicant?.dob.toString())
        const ssn = state.applicant.ssn.replace(/-/g, '')
        
        const query = new GetApplicantAccountAssociationOptionsQuery()
        query.dateOfBirth = dob
        query.ssn = ssn
        query.firstName = state.applicant.firstName ?? undefined 
        query.lastName = state.applicant.lastName ?? undefined
        query.recaptcha = await recaptcha.executeRecaptcha('ApplicantAccountAssociationOptions')

        state.applicant.applicantId = undefined
        state.applicant.status = null
        const applicant = await service.getApplicantAccountAssociationOptions(query)
        if (applicant) {
          state.applicant.status = applicant.status
          if (applicant.applicantId !== undefined) {
            state.applicant.applicantId = applicant.applicantId
          }
          if (applicant.name !== undefined) {
            state.applicant.name = applicant.name
          }
          if (applicant.district !== undefined) {
            state.applicant.district = applicant.district
            state.applicant.districtId = applicant.districtId || 0
          }
          if (applicant.school !== undefined) {
            state.applicant.school = applicant.school
            state.applicant.highSchoolId = applicant.highSchoolId || 0
          }
        }
      }
    }

    const resetApplicant = async () => {
      state.applicant.applicantId = undefined
      state.applicant.status = null
      state.applicant.name = undefined
      state.applicant.school = undefined
      state.applicant.district = undefined
    }

    const createApplicantCommand = async () => {
      await recaptcha.recaptchaLoaded()
      const cmd: RegisterApplicantCommand = new RegisterApplicantCommand()
      cmd.applicantId = state.applicant.applicantId
      cmd.ssn = state.applicant.ssn?.replace(/-/g, '')
      cmd.firstName = String(state.applicant.firstName)
      cmd.middleName = undefined
      cmd.lastName = String(state.applicant.lastName)
      cmd.street = undefined
      cmd.city = undefined
      cmd.zip = undefined
      cmd.stateId = undefined
      cmd.countyId = undefined
      cmd.phoneNumber = undefined
      cmd.raceId = undefined
      cmd.genderId = undefined
      cmd.highSchoolId = state.applicant.highSchoolId
      cmd.wasPreloaded = false
      cmd.hasCompletedWorkflow = false
      cmd.dateOfBirth = state.applicant.dob ? new Date(state.applicant.dob) : undefined
      cmd.recaptcha = await recaptcha.executeRecaptcha('CreateApplicant')
      cmd.createUserCommand = new CreateUserCommand({
        email: state.applicant.username!,
        password: state.applicant.password!
      })
      cmd.ethnicity = 0
      const result = await service.registerApplicant(cmd, undefined)
      if (result.id > 0) {
        state.accountCreated = true
        state.step++
      } else {
        state.accountCreated = false
      }
    }

    const limitSsnInput = async (value: any) => {
      let allowed = ''
      for (const v of value) {
        if (/^[0-9-]+$/i.test(v)) {
          allowed += v
        }
      }
      let result = allowed
      if (result.length > 11) {
        result = result.slice(0, 11)
      }
      return result
    }

    /* Validation */
    // email-validation step v$
    const usernameRules = computed(() => {
      return {
        applicant: {
          username: {
            required,
            email
          }
        }
      }
    })
    const v$ = useVuelidate(usernameRules, state)
    const validateUsername = async () => {
      v$.value.$touch()
      return v$.value.$errors.length === 0
    }

    // Validate applicant-school-status step v$2
    const publicSchoolStudentRules = computed(() => {
      return {
        publicSchoolStudent: { required }
      }
    })
    const v$2 = useVuelidate(publicSchoolStudentRules, state)
    const validatePublicSchoolStudent = async () => {
      v$2.value.$touch()
      return v$2.value.$errors.length === 0
    }

    // Validate applicant-link step v$3
    const ssnFormat = (value: string) => {
      const regex = /\d{3}[-]\d{2}[-]\d{4}/
      const pattern = new RegExp(regex)
      return pattern.test(value)
    }
    const maxDOBValidator = (value: string) => new Date(value) <= new Date(maxDOB.value)
    const minDOBValidator = (value: string) => new Date(value) >= new Date(minDOB.value)
    const applicantRules = computed(() => {
      return {
        applicant: {
          ssn: {
            required,
            ssnFormat: helpers.withMessage('Use ###-##-#### format', ssnFormat),
            maxLength: maxLength(11)
          },
          firstName: {
            required,
            maxLength: maxLength(100)
          },
          lastName: {
            required,
            maxLength: maxLength(100)
          },
          dob: { 
            required,
            maxValue: helpers.withMessage(`Must be before ${new Date(maxDOB.value).toLocaleDateString('us-EN', { year: 'numeric', month: 'long', day: 'numeric' })}`, maxDOBValidator),
            minValue: helpers.withMessage(`Must be after ${new Date(minDOB.value).toLocaleDateString('us-EN', { year: 'numeric', month: 'long', day: 'numeric' })}`, minDOBValidator)
          }
        }
      }
    })
    const v$3 = useVuelidate(applicantRules, state)
    const validateApplicant = async () => {
      v$3.value.$touch()
      return v$3.value.$errors.length === 0
    }

    // Validate applicant-link-results step v$4
    const applicantResultRules = computed(() => {
      return {
        applicant: {
          districtId: {
            requiredIfPublicSchool: requiredIf(() => state.publicSchoolStudent === 1),
          },
          highSchoolId: {
            requiredIfPublicSchool: requiredIf(() => state.publicSchoolStudent === 1),
          }
        }
      }
    })
    const v$4 = useVuelidate(applicantResultRules, state)
    const validateApplicantResults = async () => {
      v$4.value.$touch()
      return v$4.value.$errors.length === 0
    }

    // Validate finalize step v$5
    const finalizeRules = computed(() => {
      return {
        applicant: {
          username: {
            required,
            email
          },
          password: () => state.passwordIsValid
        }
      }
    })
    const v$5 = useVuelidate(finalizeRules, state)
    const validateFinalize = async () => {
      v$5.value.$touch()
      return v$5.value.$errors.length === 0
    }

    /* Actions */
    const actions = {
      next: async () => {
        if (currentStep.value === 'email-validation') {
          if (await validateUsername()) {
            await getApplicantByUserName()
            if (state.emailAvailable) {
              state.step++
            }
          }
        } else if (currentStep.value === 'applicant-school-status') {
          if (await validatePublicSchoolStudent()) {
            state.step++
          }
        } else if (currentStep.value === 'applicant-link') {
          if (state.applicant.ssn === '###-##-####') {
            state.applicant.ssn = null
          }
          if (await validateApplicant()) {
            await getApplicantAccountAssociationOptions()
            if (state.applicant.status != null) {
              if ((AccountAssociationStatus[state.applicant.status] === AccountAssociationStatus[AccountAssociationStatus.CanLinkToApplicant]) ||
                (state.publicSchoolStudent === 0 && AccountAssociationStatus[state.applicant.status] === AccountAssociationStatus[AccountAssociationStatus.CanCreateNewApplicantAndAccount])) {
                state.step++
              }
            }
          }
        } else if (currentStep.value === 'applicant-link-results') {
          if (state.applicant.status != null) {
            if ((AccountAssociationStatus[state.applicant.status] === AccountAssociationStatus[AccountAssociationStatus.CanLinkToApplicant]) ||
              (state.publicSchoolStudent === 0 && AccountAssociationStatus[state.applicant.status] === AccountAssociationStatus[AccountAssociationStatus.CanCreateNewApplicantAndAccount])) {
              if (await validateApplicantResults()) {
                state.step++
              }
            }
          }
        }
      },
      reset: async (event: any) => {
        if (event.target.id === 'ssn') {
          state.applicant.ssn = null
        }
        await resetApplicant()
      },
      limitInput: async (event: any) => {
        const value = event.target.value
        state.applicant.ssn = await limitSsnInput(value)
      },
      back: () => {
        state.step--
      },
      register: async () => {
        if (await validateFinalize()) {
          return createApplicantCommand()
        }
      }
    }

    return {
      steps,
      state,
      currentStep,
      actions,
      AccountAssociationStatus,
      cannotMatches,
      minDOB,
      maxDOB,
      v$, // email-validation step
      v$2, // applicant-school-status step
      v$3, // applicant-link step
      v$4, // applicant-link-results step
      v$5 // finalize step
    }
  }
})
