
import { defineComponent, reactive } from 'vue'
import { IdentityService, ResetPasswordCommand } from '@/generated/public.api.clients'
import { useRoute } from 'vue-router'
import { createApi } from '@/plugin/Api'
import PasswordFeedback from '@/components/common/PasswordFeedback.vue' 
import { useToast } from 'vue-toast-notification'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  components: {
    PasswordFeedback
  },
  setup () {
    const recaptcha = useReCaptcha()!

    const model = reactive({
      emailAddress: '',
      password: '',
      confirmPassword: '',
      newPasswordIsValid: false,
      errorResponse: undefined as undefined | string,
      submitted: false,
    })
    const toast = useToast()
    const route = useRoute()

    const canSubmit = () => {
      return model.newPasswordIsValid
    }

    const service = new IdentityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
    const resetPassword = async () => {
      await recaptcha.recaptchaLoaded()

      service.resetPassword(new ResetPasswordCommand({
        emailAddress: model.emailAddress,
        password: model.password,
        token: route.query.token as string,
        recaptcha: await recaptcha.executeRecaptcha('ResetPassword')
      })).then(() => {
        model.submitted = true
      }).catch((err) => {
        model.errorResponse = err.data.detail
        toast.error('Error updating', { duration: 0, position: 'top' })
      })
    }
    return {
      model,
      resetPassword,
      canSubmit,
    }
  }
})
